export default {
  absoluteY: 2649,
  absoluteX: 76,
  boxes: [
    {
      type: "primaryBox",
      x: 471,
      y: 155,
      strokeColor: "orange5",
      hoverColor: "orange0",
      text: "Communication",
    },
    {
      type: "multiBox",
      x: 921,
      y: 175,
      width: 207,
      height: 153,
      fillColor: "orange0",
      text: "Product Teams",
    },
    {
      type: "multiBox",
      x: 0,
      y: 0,
      width: 243,
      height: 108,
      fillColor: "orange0",
      text: "Collaborating with Stakeholders",
    },

    {
      type: "multiBox",
      x: 0,
      y: 350,
      width: 243,
      height: 153,
      fillColor: "orange0",
      text: "Interview Skills",
    },
    {
      type: "multiBox",
      x: 299,
      y: 350,
      width: 243,
      height: 153,
      fillColor: "orange0",
      text: "Design Portfolio",
    },
    {
      type: "multiBox",
      x: 0,
      y: 152,
      width: 243,
      height: 153,
      fillColor: "orange0",
      text: "Presentation Skills",
    },
    {
      type: "multiBox",
      x: 921,
      y: 350,
      width: 207,
      height: 153,
      fillColor: "orange0",
      text: "Methodologies",
    },
    {
      type: "multiBox",
      x: 921,
      y: 0,
      width: 207,
      height: 153,
      fillColor: "orange0",
      text: "Project Planning",
    },
    {
      type: "tertiaryBox",
      x: 937,
      y: 232,
      width: 175,
      fillColor: "orange0",
      strokeColor: "orange3",
      text: "Team Structure",
      hoverColor: "orange1",
    },

    {
      type: "tertiaryBox",
      x: 16,
      y: 57,
      width: 211,
      fillColor: "orange0",
      strokeColor: "orange3",
      text: "Analysis & Alignment",
      hoverColor: "orange1",
    },

    {
      type: "tertiaryBox",
      x: 937,
      y: 277,
      width: 175,
      fillColor: "orange0",
      strokeColor: "orange3",
      text: "Collaboration",
      hoverColor: "orange1",
    },
    {
      type: "tertiaryBox",
      x: 16,
      y: 407,
      width: 211,
      fillColor: "orange0",
      strokeColor: "orange3",
      text: "Interview Guide",
      hoverColor: "orange1",
    },
    {
      type: "tertiaryBox",
      x: 16,
      y: 452,
      width: 211,
      fillColor: "orange0",
      strokeColor: "orange3",
      text: "Interview Questions",
      hoverColor: "orange1",
    },
    {
      type: "tertiaryBox",
      x: 315,
      y: 407,
      width: 211,
      fillColor: "orange0",
      strokeColor: "orange3",
      text: "Best Practices",
      hoverColor: "orange1",
    },
    {
      type: "tertiaryBox",
      x: 315,
      y: 452,
      width: 211,
      fillColor: "orange0",
      strokeColor: "orange3",
      text: "Case Studies",
      hoverColor: "orange1",
    },
    {
      type: "tertiaryBox",
      x: 16,
      y: 209,
      width: 211,
      fillColor: "orange0",
      strokeColor: "orange3",
      text: "Design Presentation",
      hoverColor: "orange1",
    },
    {
      type: "tertiaryBox",
      x: 16,
      y: 254,
      width: 211,
      fillColor: "orange0",
      strokeColor: "orange3",
      text: "Collecting Feedback",
      hoverColor: "orange1",
    },
    {
      type: "tertiaryBox",
      x: 937,
      y: 407,
      width: 175,
      fillColor: "orange0",
      strokeColor: "orange3",
      text: "Agile UX",
      hoverColor: "orange1",
    },
    {
      type: "tertiaryBox",
      x: 937,
      y: 452,
      width: 175,
      fillColor: "orange0",
      strokeColor: "orange3",
      text: "Lean UX",
      hoverColor: "orange1",
    },
    {
      type: "tertiaryBox",
      x: 937,
      y: 57,
      width: 175,
      fillColor: "orange0",
      strokeColor: "orange3",
      text: "Define OKRs",
      hoverColor: "orange1",
    },
    {
      type: "tertiaryBox",
      x: 937,
      y: 102,
      width: 175,
      fillColor: "orange0",
      strokeColor: "orange3",
      text: "Design Questions",
      hoverColor: "orange1",
    },
    {
      type: "secondaryBox",
      x: 299,
      y: 232,
      width: 150,
      fillColor: "orange0",
      strokeColor: "orange4",
      text: "Soft Skills",
      hoverColor: "orange1",
    },
    {
      type: "secondaryBox",
      x: 697,
      y: 92,
      width: 150,
      fillColor: "orange0",
      strokeColor: "orange4",
      text: "Management",
      hoverColor: "orange1",
    },
  ],
};
