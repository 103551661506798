export const videoIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="22" height="22" rx="4" fill="#FA5252" />
    <path
      d="M15.213 10.1647L9.85325 6.59105V6.58968C9.69795 6.48615 9.51746 6.42672 9.33103 6.41771C9.1446 6.4087 8.95922 6.45045 8.79466 6.53852C8.6301 6.62659 8.49252 6.75766 8.3966 6.91778C8.30068 7.07789 8.25001 7.26103 8.25 7.44768V14.5963C8.25001 14.7829 8.30068 14.9661 8.3966 15.1262C8.49252 15.2863 8.6301 15.4174 8.79466 15.5055C8.95922 15.5935 9.1446 15.6353 9.33103 15.6263C9.51746 15.6173 9.69795 15.5578 9.85325 15.4543L15.213 11.8807C15.3542 11.7865 15.47 11.6589 15.5501 11.5092C15.6302 11.3596 15.6721 11.1924 15.6721 11.0227C15.6721 10.8529 15.6302 10.6858 15.5501 10.5361C15.47 10.3864 15.3542 10.2589 15.213 10.1647Z"
      fill="white"
    />
  </svg>
);

export const articleIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="22" height="22" rx="4" fill="#15AABF" />
    <path
      d="M5.83602 5.50001C5.701 5.50001 5.59038 5.60722 5.58615 5.74217L5.50809 8.22881C5.50367 8.36989 5.61683 8.48665 5.75797 8.48665H6.42002C6.55809 8.48665 6.66985 8.37339 6.67025 8.23532C6.67537 6.49305 6.79025 6.44199 9.37172 6.4405C9.50979 6.44042 9.62225 6.55238 9.62225 6.69045V14.6797C9.62225 15.6497 9.62173 15.702 8.25675 15.6835C8.11716 15.6816 8.00205 15.794 8.00205 15.9336V16.25C8.00205 16.3881 8.11398 16.5 8.25205 16.5H13.7479C13.886 16.5 13.9979 16.3881 13.9979 16.25V15.9297C13.9979 15.7916 13.8852 15.6798 13.7471 15.6795C12.3806 15.6764 12.3777 15.6227 12.3777 14.6797V6.69045C12.3777 6.55238 12.4902 6.44042 12.6283 6.4405C15.2114 6.44215 15.3322 6.49557 15.3378 8.23533C15.3383 8.3734 15.45 8.48665 15.5881 8.48665H16.2428C16.3837 8.48665 16.4967 8.37037 16.4927 8.22959L16.4224 5.74293C16.4186 5.60767 16.3078 5.5 16.1725 5.5L5.83602 5.50001Z"
      fill="white"
    />
  </svg>
);

export const bookIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="22" height="22" rx="4" fill="#F59F00" />
    <path
      d="M8.15758 15.3203C9.14634 15.3203 9.83001 15.6763 10.1351 15.8853C10.2425 15.9418 10.525 16.117 10.5984 16.1339V6.74344C10.1747 6.00893 9.01639 5.43262 7.83552 5.43262C6.27044 5.43262 4.99351 6.31403 4.66016 6.98075V15.5181C4.66581 16.0209 4.94831 16.2243 5.29862 16.2243C5.55852 16.2243 5.72238 16.1339 5.89753 15.9983C6.25914 15.7045 7.0897 15.3203 8.15758 15.3203ZM13.8416 15.3203C14.9095 15.3203 15.7344 15.7045 16.096 15.9983C16.2711 16.1339 16.435 16.2243 16.6892 16.2243C17.0396 16.2243 17.3334 16.0209 17.3334 15.5181V6.98075C17 6.31403 15.7231 5.43262 14.1636 5.43262C12.9828 5.43262 11.8188 6.00893 11.4007 6.74344V16.1452C11.4685 16.1283 11.751 15.9475 11.864 15.8853C12.1692 15.6763 12.8528 15.3203 13.8416 15.3203Z"
      fill="white"
    />
  </svg>
);
