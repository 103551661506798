import React from "react";
import { Fragment } from "react";
import logo from "../logo";

import classes from "./Header.module.css";

const Header = () => {
  return (
    <Fragment>
      <header className={classes.header}>
        <a
          href="https://github.com/anastasiaprh/product-design-roadmap"
          aria-label="View source on GitHub"
          target="blank"
          className={classes.GitHubRibbon}
        >
          <svg width="70" height="70" viewBox="0 0 250 250" aria-hidden="true">
            <path d="M0,0 L115,115 L130,115 L142,142 L250,250 L250,0 Z"></path>
            <path
              d="M128.3,109.0 C113.8,99.7 119.0,89.6 119.0,89.6 C122.0,82.7 120.5,78.6 120.5,78.6 C119.2,72.0 123.4,76.3 123.4,76.3 C127.3,80.9 125.5,87.3 125.5,87.3 C122.9,97.6 130.6,101.9 134.4,103.2"
              fill="currentColor"
            ></path>
            <path
              d="M115.0,115.0 C114.9,115.1 118.7,116.5 119.8,115.4 L133.7,101.6 C136.9,99.2 139.9,98.4 142.2,98.6 C133.8,88.0 127.5,74.4 143.8,58.0 C148.5,53.4 154.0,51.2 159.7,51.0 C160.3,49.4 163.2,43.6 171.4,40.1 C171.4,40.1 176.1,42.5 178.8,56.2 C183.1,58.6 187.2,61.8 190.9,65.4 C194.5,69.0 197.7,73.2 200.1,77.6 C213.8,80.2 216.3,84.9 216.3,84.9 C212.7,93.1 206.9,96.0 205.4,96.6 C205.1,102.4 203.0,107.8 198.3,112.5 C181.9,128.9 168.3,122.5 157.7,114.1 C157.9,116.9 156.7,120.9 152.7,124.9 L141.0,136.5 C139.8,137.7 141.6,141.9 141.8,141.8 Z"
              fill="currentColor"
            ></path>
          </svg>
        </a>

        <div className={classes["header__logo"]}>{logo}</div>
        <div className={classes["header__right-content"]}>
          <div>
            <a href="https://buy.stripe.com/fZe189eMB2kV71CdQQ" className={classes["donate-link"]} target="_blank">
              <button className={classes["donate-button"]}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.125 2.25C9.86938 2.25 8.755 2.74563 8 3.5975C7.245 2.74563 6.13062 2.25 4.875 2.25C3.78139 2.25132 2.73295 2.68635 1.95965 3.45965C1.18635 4.23295 0.751323 5.28139 0.75 6.375C0.75 10.8963 7.36312 14.5088 7.64437 14.6606C7.75367 14.7195 7.87586 14.7503 8 14.7503C8.12414 14.7503 8.24633 14.7195 8.35563 14.6606C8.63688 14.5088 15.25 10.8963 15.25 6.375C15.2487 5.28139 14.8137 4.23295 14.0404 3.45965C13.2671 2.68635 12.2186 2.25132 11.125 2.25ZM10.7819 11.1475C9.91142 11.8861 8.98091 12.5509 8 13.135C7.01909 12.5509 6.08858 11.8861 5.21812 11.1475C3.86375 9.98563 2.25 8.21375 2.25 6.375C2.25 5.67881 2.52656 5.01113 3.01884 4.51884C3.51113 4.02656 4.17881 3.75 4.875 3.75C5.9875 3.75 6.91875 4.3375 7.30562 5.28375C7.36193 5.42169 7.45805 5.53974 7.58172 5.62283C7.70539 5.70592 7.85101 5.7503 8 5.7503C8.14899 5.7503 8.29461 5.70592 8.41828 5.62283C8.54195 5.53974 8.63807 5.42169 8.69438 5.28375C9.08125 4.3375 10.0125 3.75 11.125 3.75C11.8212 3.75 12.4889 4.02656 12.9812 4.51884C13.4734 5.01113 13.75 5.67881 13.75 6.375C13.75 8.21375 12.1362 9.98563 10.7819 11.1475Z" fill="white"/>
                </svg>
                Donate
              </button>
            </a>
          </div>
          <div className={classes["social-icons"]}>
            <span>Share:</span>
            <a
              href="https://twitter.com/intent/tweet?text=Product%20Design%20Roadmap&url=https%3A%2F%2Fproduct-design-roadmap.com%2F"
              target="_blank"
              rel="noopener noreferrer"
              className={classes["twitter-ic"]}
            >
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 13.6025C1.93756 13.7116 3.63187 13.2586 5.28425 12.0592C3.6151 11.774 2.4576 11.0107 1.82852 9.43386C2.31501 9.36676 2.74278 9.49257 3.24605 9.29127C1.60206 8.56992 0.536815 7.48791 0.494876 5.61745C1.01491 5.65939 1.40914 6.00329 2.02983 5.95296C0.520043 4.47673 0.0587139 2.86629 1.00652 0.911955C2.58341 2.7237 4.42871 3.95669 6.685 4.53544C6.81082 4.56899 6.92824 4.60254 7.05405 4.62771C7.6328 4.76191 8.3206 5.04709 8.67288 5.00515C9.2768 4.92966 8.67289 4.23348 8.82387 3.32761C9.30196 0.509346 12.4054 -0.773973 14.7036 0.878404C15.3747 1.36489 15.9031 1.3565 16.5657 1.06293C16.9096 0.911955 17.2619 0.760976 17.6729 0.584835C17.5806 1.39005 16.9599 1.8346 16.4651 2.43013C17.027 2.55594 17.48 2.39658 18 2.22882C17.8239 2.80758 17.3877 3.1347 16.9935 3.45343C16.5825 3.78055 16.4231 4.12444 16.4063 4.66126C16.1547 12.7889 6.91986 19.0713 0.60392 14.0723C-0.00838157 13.5858 0.587139 14.0723 0 13.6025Z"
                  fill="#ADB5BD"
                />
              </svg>
            </a>
            <a
              href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fproduct-design-roadmap.com%2F"
              target="_blank"
              rel="noopener noreferrer"
              className={classes["fb-ic"]}
            >
              <svg
                width="11"
                height="18"
                viewBox="0 0 11 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.33728 10.4645V18H2.87574V10.4645H0V7.40902H2.87574V6.29734C2.87574 2.17012 4.59985 0 8.24778 0C9.36612 0 9.64571 0.179734 10.2581 0.326183V3.34837C9.57249 3.22855 9.37944 3.16198 8.66716 3.16198C7.82175 3.16198 7.36908 3.40163 6.95636 3.87426C6.54364 4.34689 6.33728 5.16568 6.33728 6.33728V7.41568H10.2581L9.20636 10.4712H6.33728V10.4645Z"
                  fill="#ADB5BD"
                />
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/sharing/share-offsite/?url=https://product-design-roadmap.com"
              target="_blank"
              rel="noopener noreferrer"
              className={classes["linkedin-ic"]}
            >
              <svg
                width="19"
                height="18"
                viewBox="0 0 19 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.32176 5.91895H0.560547V17.9132H4.32176V5.91895Z"
                  fill="#ADB5BD"
                />
                <path
                  d="M14.4994 5.65908C14.3608 5.64174 14.2134 5.63308 14.0661 5.62441C11.9602 5.53775 10.7729 6.78571 10.3569 7.32302C10.2443 7.47035 10.1922 7.55701 10.1922 7.55701V5.95373H6.5957V17.948H10.1922H10.3569C10.3569 16.726 10.3569 15.5127 10.3569 14.2908C10.3569 13.6321 10.3569 12.9735 10.3569 12.3149C10.3569 11.5002 10.2962 10.6336 10.7036 9.88827C11.0502 9.26429 11.6742 8.9523 12.3762 8.9523C14.4561 8.9523 14.4994 10.8329 14.4994 11.0062C14.4994 11.0149 14.4994 11.0236 14.4994 11.0236V18H18.2606V10.1743C18.2606 7.49635 16.9 5.91907 14.4994 5.65908Z"
                  fill="#ADB5BD"
                />
                <path
                  d="M2.44174 4.36785C3.64788 4.36785 4.62567 3.39008 4.62567 2.18393C4.62567 0.977778 3.64788 0 2.44174 0C1.23559 0 0.257812 0.977778 0.257812 2.18393C0.257812 3.39008 1.23559 4.36785 2.44174 4.36785Z"
                  fill="#ADB5BD"
                />
              </svg>
            </a>
          </div>
        </div>

      </header>
    </Fragment>
  );
};

export default Header;
